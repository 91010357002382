import * as React from 'react';
import {useState} from 'react';
import {styled} from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import Layout from "../components/layout";
import BaseContainer from '../components/common/BaseContainer';
import {getImage} from "gatsby-plugin-image";
import BackgroundImage from 'gatsby-background-image';
import {graphql, useStaticQuery} from "gatsby";
import {convertToBgImage} from "gbimage-bridge";
import Seo from "../components/seo";

const items = [
  {
    question: 'Czym jest licencja na publiczne odtwarzanie muzyki?',
    answer: "Zgodnie z ustawą o prawie autorskim i prawach pokrewnych odtwarzanie muzyki w miejscach powszechnie dostępnych (np. sklepy, restauracje, bary, salony fryzjerskie, kosmetyczne i inne zakłady usługowe) wymaga co do zasady zgody twórców, artystów wykonawców i producentów nagrań oraz uiszczenia odpowiedniego wynagrodzenia. Umowa zawierająca taką zgodę nosi nazwę licencji. Większość osób pragnących odtwarzać w swoich lokalach/placówkach/obiektach muzykę nabywa stosowne licencje od działających na rynku organizacji zbiorowego zarządzania (STOART, SAWP, ZPAV, ZAiKS), jednak istnieją również inne prawnie dopuszczalne rozwiązania przy zastosowaniu których muzyka bez opłat do OZZ odtwarzana jest legalnie."
  },
  {
    question: <>Dlaczego warto korzystać z muzyki do publicznego odtwarzania z&nbsp;MUSIC POINT?</>,
    answer: 'MUSIC POINT to najwyższa muzyczna jakość, to zbiór wyjątkowych, starannie dobranych, ekskluzywnych nagrań (nie jest to produkowana na masową skalę muzyka royalty free). Muzyka oferowana jest od razu z licencją na publiczne odtwarzanie. ' +
      'Oznacza to, że publiczne odtwarzanie muzyki z MUSIC POINT (np. w sklepie lub w zakładzie usługowym) nie wymaga już wykupienia licencji od organizacji zbiorowego zarządzania (STOART, SAWP, ZPAV, ZAiKS). Pozwala to na znaczne obniżenie kosztów i zminimalizowanie wszelkich formalności, które należy załatwić tylko z jedną firmą MUSIC POINT.',
  },
  {
    question: <>Kto może korzystać z muzyki oferowanej<br/> do publicznego odtwarzania w serwisie MUSIC POINT?</>,
    answer: 'Nasza oferta jest skierowana do osób prawnych, jednostek organizacyjnych i osób fizycznych. Osoby fizyczne mogą jednak dokonać zakupu wyłącznie na potrzeby swojej działalności gospodarczej lub zawodowej.',
  },
  {
    question: 'Czy mogę korzystać z usług MUSIC POINT jeżeli podpisałem(am) już umowę z organizacją zbiorowego zarządzania (STOART, SAWP, ZPAV, ZAiKS)?',
    answer: 'Tak. Osoba, która zamierza odtwarzać w swoim lokalu/placówce/obiekcie wyłącznie muzykę oferowaną przez MUSIC POINT może jednak rozwiązać zawartą wcześniej umowę z organizacją zbiorowego zarządzania.',
  },
  {
    question: 'Czy mogę mieć pewność, że odtwarzając muzykę z MUSIC POINT w moim ' +
      'lokalu/placówce/obiekcie i nie wnosząc opłat za publiczne odtwarzanie muzyki ' +
      'do organizacji zbiorowego zarządzania (STOART, SAWP, ZPAV, ZAiKS) – nie naruszam prawa?',
    answer: 'Gwarantujemy, że dysponujemy wszystkimi prawami niezbędnymi dla udzielenia licencji. Odtwarzając muzykę z MUSIC POINT jest się zwolnionym z innych dodatkowych opłat za publiczne odtwarzanie muzyki do organizacji zbiorowego zarządzania (STOART, SAWP, ZPAV, ZAiKS), pod warunkiem, że odtwarzana jest wyłącznie muzyka z MUSIC POINT i dysponujecie Państwo oryginałem ważnej licencji udzielonej przez firmę Music Point Sp. z o.o.',
  },
  {
    question: 'Czy zwolnienie z obowiązku uiszczania opłat za publiczne odtwarzanie muzyki udostępnianej przez MUSIC POINT obejmuje wszystkie działające na rynku organizacje zbiorowego zarządzania?',
    answer: 'Tak. Do wszystkich naszych nagrań dysponujemy zarówno prawami autorskimi, jak i pokrewnymi. Zakres przysługujących nam praw pokrywa zatem zakresy działania wszystkich działających na rynku organizacji zbiorowego zarządzania.',
  },
  {
    question: 'Gdy odtwarzam muzykę z MUSIC POINT, czy będę mógł (mogła) w dalszym ciągu odtwarzać w lokalu/placówce/obiekcie muzykę kupioną od innych podmiotów?',
    answer: 'Tak, jednak odtwarzanie muzyki nabytej od innych podmiotów wymaga nabycia odrębnej licencji od osób uprawnionych, tj. najczęściej od organizacji zbiorowego zarządzania (STOART, SAWP, ZPAV, ZAiKS). Music Point Sp. z o.o. nie bierze na siebie żadnej odpowiedzialności za konsekwencje odtwarzania muzyki zakupionej u osób trzecich.',
  },
  {
    question: 'Czy muzykę z MUSIC POINT można odtwarzać w dowolnym miejscu?',
    answer: 'Nie. Składając zamówienie należy dokładnie określić lokalizację / lokalizacje w których muzyka ma być odtwarzana.Nie wyłącza to oczywiście możliwości słuchania muzyki w innych miejscach dla celów prywatnych.',
  },
  {
    question: 'Co zatem zrobić, żeby móc odtwarzać muzykę w wielu różnych lokalach/placówkach/obiektach?',
    answer: 'W takim wypadku należy wykupić licencję dla każdej lokalizacji lub jedną zbiorczą licencję obejmującą wszystkie lokalizacje.',
  },
  {
    question: 'Przez jaki okres mogę odtwarzać w swoim lokalu/placówce/obiekcie muzykę z MUSIC POINT?',
    answer: 'O tym decyduje okres obowiązywania licencji na publiczne odtwarzanie określony w umowach zawieranych z MUSIC POINT przez klientów indywidualnych a w przypadku sieci handlowych przez organizatora sieci lub właściciela sieci.',
  },
];

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({theme}) => ({
  borderBottom: `1px solid ${theme.palette.divider}`,
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{fontSize: '1.5rem', color: 'primary.main'}}/>}
    {...props}
  />
))(({theme}) => ({
  color: theme.palette.primary.main,
  fontWeight: 'bold',
  flexDirection: 'row-reverse',
  alignItems: 'flex-start',
  '& .MuiAccordionSummary-expandIconWrapper': {
    transform: 'translateY(8px)',
  },
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'translateY(8px) rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    margin: 0,
    marginLeft: theme.spacing(1),
  },
}));

const HeroText = styled(Typography)({
  fontSize: 120,
  lineHeight: '112px',
})

const AccordionDetails = styled(MuiAccordionDetails)(({theme}) => ({
  padding: theme.spacing(2),
}));

export default function FaqPage() {
  const [isOpen, setIsOpen] = useState(items.map(() =>  false));
  const {placeholderImage} = useStaticQuery(
    graphql`
      query {
        placeholderImage: file(relativePath: { eq: "sluchawki_faq_wide.png" }) {
          childImageSharp {
            gatsbyImageData(
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    `
  )
  const image = getImage(placeholderImage)

  const bgImage = convertToBgImage(image)

  return (
    <Layout>
      <Seo title='FAQ'/>
      <BackgroundImage
        Tag="section"
        {...bgImage}
        style={{
          backgroundPosition: 'center right',
          backgroundSize: 'auto 100%',
      }}
        preserveStackingContext
      >
        <BaseContainer sx={{py: 10}}>
          <HeroText variant='h2' component="h1" sx={{color: 'white'}}>
            Najczęściej<br/>
            zadawane<br/>
            pytania
          </HeroText>
          <HeroText variant='h2' sx={{color: 'primary.light'}}>
            Music Point
          </HeroText>
        </BaseContainer>
      </BackgroundImage>
      <section>
        <BaseContainer>{
          items.map(({question, answer}, idx) => (
            <Accordion
              key={idx}
              sx={{
                py: 4,
                '& .MuiAccordion-root': { borderColor: 'red'}
            }}
              expanded={isOpen[question]}
              onChange={() => {
                const updated = [...isOpen];
                updated[idx] = !isOpen[idx];
                setIsOpen(updated);
              }}
            >
              <AccordionSummary>
                <Typography sx={{mb: 0}} fontWeight={700}>{question}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography sx={{mb: 0}}>{answer}</Typography>
              </AccordionDetails>
            </Accordion>
          ))
        }</BaseContainer>
      </section>
    </Layout>
  );
}

